<template>
	<div
		class="radiobutton"
		:class="[size, classes]"
		:tabindex="disabled ? -1 : 0"
		@keyup.tab="keyboardFocus = true"
		@focusout="keyboardFocus = false"
		@keydown.space.prevent="
			handleInput();
			handleChange();
		"
		@keydown.enter.prevent="
			handleInput();
			handleChange();
		"
	>
		<label
			class="radiobutton--container"
			:class="{ flip }"
		>
			<!-- @slot Slot to replace default radiobutton design with custom markup or svg -->
			<!-- @binding {boolean} isChecked, disabled, {String} size -->
			<slot
				name="checkmark"
				v-bind="{ isChecked, disabled, size }"
			>
				<input
					type="radio"
					class="radiobutton--input"
					:class="{ error }"
					:name="name"
					:value="value"
					:checked="isChecked"
					:disabled="disabled"
					tabindex="-1"
					v-bind="$attrs"
					@change="handleChange"
					@input="handleInput"
				/>
			</slot>
			<!-- @slot Slot for custom label markup -->
			<!-- @binding {string} label -->
			<slot
				name="label"
				v-bind="{ label }"
			>
				<span
					v-if="label"
					class="radiobutton--label"
					:class="`font-text-${size || 'm'}`"
					>{{ label }}</span
				>
			</slot>
		</label>
	</div>
</template>

<script>
import sizes from "./definitions/sizes";

/**
 * TnRadiobutton llow users to select one item from a list and should only be used when only one selection is allowed.
 * @displayName TnRadiobutton
 */
export default defineComponent({
	name: "TnRadiobutton",

	model: {
		prop: "selected",
		event: "input",
	},

	props: {
		/**
		 * Specifies the name of the radiobutton for forms
		 */
		name: {
			type: String,
			default: "",
		},
		/**
		 * The string to use as the radiobutton value when submitting the form, etc
		 */
		value: {
			type: [String, Number, Boolean],
			default: "",
		},
		/**
		 * The label/text displayed at the right-hand side of the radiobutton. @see <slots> for options for customizing all markup
		 */
		label: {
			type: String,
			default: "",
		},
		/**
		 * Disables the radiobutton
		 */
		disabled: {
			type: Boolean,
			default: false,
		},
		/**
		 * Sets the size for the radiobutton
		 * @values s, m, l
		 */
		size: {
			type: String,
			default: "m",
			validator: function (value) {
				return sizes.indexOf(value.toLowerCase()) !== -1;
			},
		},
		/**
		 * Selected value
		 */
		selected: {
			default: "",
		},
		/**
		 * Flips the order of the checkmark and label
		 */
		flip: {
			type: Boolean,
			default: false,
		},
		/**
		 * Error/invalid state. Use to validate
		 */
		error: {
			type: Boolean,
			default: false,
		},
		/**
		 * Apply dark theming
		 */
		dark: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			keyboardFocus: false,
		};
	},

	computed: {
		isChecked() {
			return typeof this.selected === "boolean" ? this.selected : this.value === this.selected;
		},
		classes() {
			return {
				"radiobutton--disabled": this.disabled,
				"radiobutton--error": this.error,
				"radiobutton--dark": this.dark,
				"radiobutton--focus": !this.disabled && this.keyboardFocus,
				"radiobutton--checked": this.isChecked,
			};
		},
	},

	methods: {
		handleChange() {
			this.$emit("change", this.value);
		},
		handleInput() {
			this.$emit("input", this.value);
		},
	},
});
</script>

<style lang="scss" scoped>
@use "@/assets/scss/variables" as variables;
@use "@/assets/typography/css/typography.css";
@use "sass:color";

@function hexToRGB($hex) {
	@return color.channel($hex, "red", $space: rgb), color.channel($hex, "green", $space: rgb),
		color.channel($hex, "blue", $space: rgb);
}

.radiobutton {
	--radiobutton-text-color: #{variables.$color-primary-dark};
	--radiobutton-bg-color: #{variables.$color-neutrals-white};
	--radiobutton-bg-hover-color: #{variables.$color-cta-hover-background};
	--radiobutton-border-color: #{variables.$color-cta-default};
	--radiobutton-dot-color: #{variables.$color-cta-default};
	--radiobutton-focus-color: #{variables.$color-cta-focus};
	--radiobutton-hover-effect: rgba(#{hexToRGB(variables.$color-cta-hover-background)}, 0.08);
	--radiobutton-active-color: #{variables.$color-cta-active};

	outline: none;

	* {
		margin: 0;
		padding: 0;
	}

	input[type="radio"] {
		width: 20px;
		height: 20px;
		appearance: none;
		cursor: pointer;
		box-sizing: border-box;
		border-radius: 100%;
		border-width: 1.5px;
		border-style: solid;
		border-color: var(--radiobutton-border-color);
		background-color: var(--radiobutton-bg-color);
		flex-shrink: 0;
		display: flex;
		justify-content: center;
		align-items: center;

		&::after {
			content: "";
			position: absolute;
			border-radius: 8px;
			background-color: var(--radiobutton-dot-color);
			width: 10px;
			height: 10px;
			display: none;
		}

		&:checked {
			&::after {
				display: block;
			}
		}
	}

	&--label {
		margin-left: 8px;
		color: var(--radiobutton-text-color);
	}

	&--container {
		min-height: 44px;
		display: flex;
		align-items: center;
		cursor: pointer;
		color: var(--radiobutton-text-color);

		&:hover {
			.radiobutton--input:not(:disabled, :checked) {
				background: var(--radiobutton-hover-effect);

				&.radiobutton--input::after {
					display: block;
					opacity: 0.2;
				}
			}
		}

		&:active {
			.radiobutton--input:not(:disabled, .error) {
				--radiobutton-border-color: var(--radiobutton-active-color);
				--radiobutton-dot-color: var(--radiobutton-active-color);
			}
		}

		&.flip :last-child {
			order: -1;
		}
	}

	&--error {
		--radiobutton-border-color: #{variables.$color-critical-500-core};
		--radiobutton-dot-color: #{variables.$color-critical-500-core};
	}

	&--disabled {
		--radiobutton-border-color: #{variables.$color-cta-disabled};
		--radiobutton-dot-color: #{variables.$color-cta-disabled};

		* {
			cursor: not-allowed;
		}
	}

	&--focus {
		.radiobutton--input {
			outline: 2px solid var(--radiobutton-focus-color);
			outline-offset: 3px;
		}
	}

	&--dark {
		--radiobutton-text-color: #{variables.$color-primary-superlight};
		--radiobutton-bg-color: transparent;
		--radiobutton-bg-hover-color: rgba(#{hexToRGB(variables.$color-cta-dark-hover-background)}, 0.24);
		--radiobutton-border-color: #{variables.$color-cta-dark-default};
		--radiobutton-dot-color: #{variables.$color-cta-dark-hover};
		--radiobutton-focus-color: #{variables.$color-cta-dark-focus};
		--radiobutton-hover-effect: rgba(#{hexToRGB(variables.$color-cta-dark-hover-background)}, 0.24);
		--radiobutton-active-color: #{variables.$color-cta-dark-active};

		&.radiobutton--error {
			--radiobutton-border-color: #{variables.$color-critical-500-core};
			--radiobutton-bg-color: #{variables.$color-critical-800-shade};
			--radiobutton-bg-hover-color: var(--radiobutton-bg-color);
			--radiobutton-dot-color: #{variables.$color-critical-500-core};
			--radiobutton-hover-effect: var(--radiobutton-bg-color);
		}

		&.radiobutton--disabled {
			--radiobutton-border-color: #{variables.$color-cta-dark-disabled};
			--radiobutton-bg-color: #{variables.$color-cta-dark-disabled};
			--radiobutton-dot-color: #{variables.$color-neutrals-600-shade};
		}
	}
}
</style>
